<!--
 * @Author: jiang
 * @Date: 2021-07-12 11:05:52
 * @Description:
-->
<template>
  <div
    v-loading="loading"
    style="min-height: 240px;"
  >
    <el-row
      :gutter="20"
      v-if="formData.title"
    >
      <el-col>
        <b-info-item
          label="标题"
          :value="formData.title"
        ></b-info-item>
      </el-col>
      <el-col :span="12">
        <b-info-item
          label="类型"
          :value="formData.type"
        ></b-info-item>
      </el-col>
      <el-col :span="12">
        <b-info-item
          label="创建时间"
          :value="formData.created_at"
        ></b-info-item>
      </el-col>
      <el-col :span="12">
        <b-info-item
          label="部门"
          :value="formData.department.name"
        ></b-info-item>
      </el-col>
      <el-col :span="12" v-if="formData.order">
        <b-info-item
          label="排序"
          :value="formData.order"
        ></b-info-item>
      </el-col>
      <el-col v-if="formData.content">
        <b-info-item label="内容">
          <div v-html="formData.content"></div>
        </b-info-item>
      </el-col>
      <el-col>
        <b-info-item label="附件">
          <c-media-list :list="formData.media"></c-media-list>
        </b-info-item>
      </el-col>
    </el-row>
  </div>

</template>

<script>
import CMediaList from '@/components/upload/media-list'
import { getRecods } from '@/api/manager'
export default {
  props: {
    id: Number,
  },
  components: {
    CMediaList,
  },
  watch: {
    id: {
      handler(val) {
        if (val) {
          this.fetchData()
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      loading: false,
      formData: {},
    }
  },
  methods: {
    fetchData() {
      this.loading = true
      getRecods(this.id)
        .then((res) => {
          this.formData = res
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
